import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { useTheme } from "../../theme/ThemeContext";
import IconButton from "../IconButton/IconButton";
import { IconNames } from "../Icon/Icon";
import { Title } from "../Text/Text";
import Button from "../Button/Button";

interface Props {
  isVisible: boolean;
  setIsVisible: () => void;
  children?: JSX.Element | React.ReactNode;
  modalWindowStyle?: React.CSSProperties;
  modalContentStyle?: React.CSSProperties;
  disabledSubmit?: boolean;
  onAccept?: () => void;
}

type Root = HTMLElement | DocumentFragment | null;

const modalRoot: Root = document.querySelector("#modal-root") as HTMLElement;

export default function Modal({ setIsVisible, isVisible, onAccept,children }: Props) {
  const theme = useTheme();

  React.useEffect(() => {
    const onEscapeClose = (e: KeyboardEvent): void => {
      if (e.code === "Escape") {
        setIsVisible();
      }
    };

    document.addEventListener("keydown", onEscapeClose);
    return () => document.removeEventListener("keydown", onEscapeClose);
  }, [setIsVisible]);

  const handleCloseModal = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    const { target, currentTarget } = e;

    if (target === currentTarget) {
      setIsVisible();
    }
  };

  const handleAccept = () => {
    if (onAccept) {
      setIsVisible();
      onAccept();
    }
  };

  const modal = (
    <AnimatePresence>
      {isVisible && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Backdrop onClick={handleCloseModal}>
            <StyledModal>
              <Header>
                {" "}
                <IconButton
                  appearance="round"
                  buttonStyle={{ backgroundColor: "transparent", padding: 0 }}
                  iconSize={["22px", "22px"]}
                  iconName={IconNames.Cross}
                  onClick={setIsVisible}
                  iconColor={theme.color.white}
                />
              </Header>
              <ContentWrapper>
                {children ? (
                  children
                ) : (
                  <>
                    <Title>Are you sure you want to finish call?</Title>
                    <ButtonsGroup>
                      <Button
                        buttonStyles={{ backgroundColor: theme.color.black, padding: theme.px.x4 }}
                        title="Finish"
                        onClick={handleAccept}
                      />
                      <Button
                        buttonStyles={{ padding: theme.px.x4 }}
                        title="Continue"
                        onClick={setIsVisible}
                      />
                    </ButtonsGroup>
                  </>
                )}
              </ContentWrapper>
            </StyledModal>
          </Backdrop>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return isVisible ? createPortal(modal, modalRoot!) : null;
}

const Backdrop = styled.div(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: "0px",
  zIndex: 2000,
  visibility: "visible",
  opacity: "1",
  backgroundColor: theme.color.opacityColors.black(0.45),
}));

const StyledModal = styled.div(({ theme }) => ({
  width: "343px",
  borderRadius: theme.px.x4,
  position: "absolute",
  top: "50%",
  left: "50%",
  overflow: "hidden",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.color.mainBg,
}));

const Header = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  height: "54px",
  backgroundColor: theme.color.accentColor,
  padding: theme.px.x4,
}));

const ContentWrapper = styled.div(({ theme }) => ({
  display: "grid",
  gap: theme.px.x3,
  padding: theme.px.x4,
  textAlign: "center",
}));

const ButtonsGroup = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "147px 147px",
  gap: theme.px.x4,
  justifyContent: "center",
  alignItems: "center",
}));
