import { createContext, useContext, Dispatch } from 'react';

const DeviceContext = createContext<{ device: {}; setDevice: Dispatch<{}> }>({
  device: {},
  setDevice: () => {},
});

const DeviceProvider = DeviceContext.Provider;

const useDevice = () => {
  return useContext(DeviceContext);
};

export { DeviceContext, DeviceProvider, useDevice };
