export function getAttributesAsObject(element) {
  const attributesObject = {};

  if (element && element.attributes) {
    const attributes = element.attributes;

    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      attributesObject[attribute.name] = attribute.value;
    }
  }

  return attributesObject;
}
