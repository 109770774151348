import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button/Button";
import Modal from "src/components/Modal/Modal";
import { Subtitle, Text } from "src/components/Text/Text";
import { useCheckPermissions } from "src/hooks";
import { useTheme } from "src/theme/ThemeContext";

export default function Permissions() {
  const { requestPermissions, cameraPermissionState, microphonePermissionState } =
    useCheckPermissions();
  const navigate = useNavigate();
    const theme = useTheme();
    
  useEffect(() => {

    if (cameraPermissionState === "granted" && microphonePermissionState === "granted") {
      navigate("/call");
    }
  }, [cameraPermissionState, microphonePermissionState, navigate]);

  return (
    <Modal
      isVisible
      onAccept={requestPermissions}
      setIsVisible={() => {
        navigate("/");
      }}
    >
      <Subtitle color={theme.color.secondaryText}>Your camera access is blocked</Subtitle>
      <Text color={theme.color.secondaryText}>
        We can’t continue without video. To connect with sign language interpreter, allow access to
        your camera and microphone.
      </Text>
      <Button
        title="allow"
        onClick={() => {
          requestPermissions();
        }}
      />
    </Modal>
  );
}
