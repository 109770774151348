import React from "react";
import styled from "styled-components";

import Icon, { IconNames } from "../../components/Icon/Icon";
import { ButtonText } from "../Text/Text";

interface Props {
  title: string;
  iconName?: IconNames;
  onClick:
    | ((e: any) => Promise<void>)
    | (() => void)
    | ((e: React.MouseEvent<HTMLButtonElement>) => void)

  disabled?: boolean;
  buttonStyles?: React.CSSProperties;
}

export default function Button({ title, iconName, onClick, disabled, buttonStyles }: Props) {
  return (
    <StyledButton style={buttonStyles} disabled={disabled} onClick={onClick}>
      {iconName ? <Icon name={iconName} size="32px" /> : null}
      <ButtonText>{title}</ButtonText>
    </StyledButton>
  );
}

const StyledButton = styled.button(({ theme, disabled }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  gap: theme.px.x4,
  padding: theme.px.x2,
  color: theme.color.white,
  backgroundColor: disabled ? `${theme.color.accentColor}4d` : `${theme.color.accentColor}`, // Use 'FF' for fully opaque color
  outline: "none",
  border: "1px solid transparent",
  cursor: disabled ? "not-allowed" : "pointer",

  "&:hover": {
    backgroundColor: disabled ? `${theme.color.accentColor}4d` : `${theme.color.accentColor}b3`, // Use 'b3' for 70% opacity (adjust as needed)
  },
}));

