import { ColorMap, ColorPalette, OpacityMap } from "./types";

export const colors: ColorPalette = {
  mainText: "#FFFFFF",
  secondaryText: "#000000",
  textAdditional: "#A1A0AD",
  accentColor: "red",
  black: "#000",
  white: "#fff",
  mainBg: "#F4F4F4",
  disabled: "#766aee4d",
  red: "#DD4746",
};

// calculating opacity colors
export const opacityColors: OpacityMap = {
  black: (opacity: number) => `rgba(0, 0, 0, ${opacity})`,
  gray: (opacity: number) => `rgba(118, 118, 128, ${opacity})`,
  white: (opacity: number) => `rgba(255, 255, 255, ${opacity})`,
};

export const color: ColorMap = {
  ...colors,
  opacityColors,
};
