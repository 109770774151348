import { useCallback, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io-client/dist/socket.io';

type SetWidgetSocketType = {
  apiKey: string;
  hostname: string;
};

type ReturnType = {
  setWidgetSocket: ({
    apiKey,
    hostname,
  }: {
    apiKey: string;
    hostname: string;
  }) => (() => void) | undefined;
  disconnectWidgetSocket: () => void;
  widgetSocket: React.MutableRefObject<Socket<
    DefaultEventsMap,
    DefaultEventsMap
  > | null>;
};

export const useWidgetSocket = (): ReturnType => {
  const widgetSocket = useRef<Socket<
    DefaultEventsMap,
    DefaultEventsMap
  > | null>(null);
  const apiKeyRef = useRef<null | string>(null);
  const hostnameRef = useRef<null | string>(null);

  const setWidgetSocket = useCallback(
    ({ apiKey, hostname }: SetWidgetSocketType) => {
      if (!apiKey || !hostname) return;

      if (apiKeyRef.current === apiKey && hostnameRef.current === hostname)
        return;

      apiKeyRef.current = apiKey;
      hostnameRef.current = hostname;
      const optionsWidget = {
        path: '/v2',
        forceNew: true,
        extraHeaders: {
          'x-api-key': apiKey,
          'x-api-domain': hostname,
        },
      };
      widgetSocket.current = io(
        `${process.env.REACT_APP_SOCKET_URL}/widget`,
        optionsWidget,
      );

      widgetSocket.current.on('connect', () => {
        console.log('widgetSocket connected: ', widgetSocket.current?.id);
      });

      widgetSocket.current.on('disconnect', () => {
        console.log('widgetSocket disconnected: ', widgetSocket.current?.id);
      });

      widgetSocket.current.on('exception', payload => {
        console.log('widgetSocket exception', payload);
      });

      widgetSocket.current.on('error', error => {
        console.log('widgetSocket error: ', error);
      });

      return () => {
        disconnectWidgetSocket();
      };
    },
    [],
  );

  function disconnectWidgetSocket() {
    console.log('disconnect widget socket');
    widgetSocket.current?.disconnect();
  }

  return {
    setWidgetSocket,
    disconnectWidgetSocket,
    widgetSocket,
  };
};
