import { rootElement } from "src";
import { getAttributesAsObject } from "./getAttributesAsObject";

export const getInjectedStyle = (): Record<string, string> => {
  const attributes: Record<string, string> = getAttributesAsObject(rootElement);

  const allowedProperties = ["top", "left", "bottom", "right"];

  return attributes.style
    .split(";")
    .map((propertyValue) => propertyValue.trim())
    .filter(Boolean)
    .reduce((acc, propertyValue) => {
      const [property, value] = propertyValue.split(":");
      const trimmedProperty = property.trim();
      if (allowedProperties.includes(trimmedProperty)) {
        acc[trimmedProperty] = value.trim().endsWith("px") ? `-${value.trim()}` : value.trim();
      }
      return acc;
    }, {});
};
