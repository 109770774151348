import { PxMap } from "./types";

export const pxs: PxMap = {
  x0: "0rem",
  x1: "0.25rem", // 4px
  x2: "0.5rem", // 8px
  x3: "0.75rem", // 12px
  x4: "1rem", // 16px
  x5: "1.25rem", // 20px
  x6: "1.5rem", // 24px
  x7: "1.75rem", // 28px
  x8: "2rem", // 32px
  x9: "2.25rem", // 36px
  x10: "2.5rem", // 40px
  x11: "2.75rem", // 44px
};
