import { createContext, Dispatch, useState } from 'react';

type WidgetConfigType = {
  apiKey: string;
  hostname: string;
  socketId: string;
};

export const widgetConfigInitialState = {
  apiKey: '',
  hostname: '',
  socketId: '',
};

export const WidgetConfigContext = createContext<{
  widgetConfig: WidgetConfigType;
  setWidgetConfig: Dispatch<WidgetConfigType>;
}>({
  widgetConfig: widgetConfigInitialState,
  setWidgetConfig: () => {},
});

export const WidgetConfigProvider = ({ children }) => {
  const [widgetConfig, setWidgetConfig] = useState(widgetConfigInitialState);
  return (
    <WidgetConfigContext.Provider value={{ widgetConfig, setWidgetConfig }}>
      {children}
    </WidgetConfigContext.Provider>
  );
};
