export const prependMarginAndCapitalize = (
  styleObject: Record<string, string>
): Record<string, string> => {
  const result: Record<string, string> = {};

  for (const property in styleObject) {
    if (styleObject.hasOwnProperty(property)) {
      const capitalizedProperty = property.charAt(0).toUpperCase() + property.slice(1);
      result[`margin${capitalizedProperty}`] = styleObject[property];
    }
  }

  return result;
};
