import React from "react";
import styled from "styled-components";

import Icon, { IconNames } from "../../components/Icon/Icon";

enum ButtonAppearance {
  ROUND = "round",
  STRICT = "strict",
}

interface Props {
  iconName: IconNames;
  iconSize?: [string, string];
  appearance?: `${ButtonAppearance}`;
  buttonStyle?: React.CSSProperties;
  iconColor?: string;
  onClick?: React.MouseEventHandler;
}

export default function IconButton({
  appearance = "strict",
  iconName,
  iconSize = ["32px", "32px"],
  iconColor,
  buttonStyle,
  onClick,
}: Props) {
  const [iconWidth, iconHeight] = iconSize;

  return (
    <Button onClick={onClick} style={buttonStyle} $appereance={appearance}>
      <Icon color={iconColor} name={iconName} width={iconWidth} height={iconHeight} />
    </Button>
  );
}

const Button = styled.button<{ $appereance: `${ButtonAppearance}` }>(({ theme, $appereance }) => ({
  backgroundColor:
    $appereance === ButtonAppearance.STRICT ? theme.color.red : "rgba(255, 255, 255, 0.16)",
  borderRadius: $appereance === ButtonAppearance.STRICT ? 0 : "50%",
  border: "1px solid transparent",
  padding: theme.px.x4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor:"pointer"
}));
