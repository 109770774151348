import { useEffect, useState } from "react";

export const useCheckPermissions = () => {
  const [cameraPermissionState, setCameraPermissionState] = useState("prompt");
  const [microphonePermissionState, setMicrophonePermissionState] = useState("prompt");

  useEffect(() => {
    const checkPermissions = async () => {
      const cameraPermission = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });

      const microphonePermission = await navigator.permissions.query({
        name: "microphone" as PermissionName,
      });

      setCameraPermissionState(cameraPermission.state);
      setMicrophonePermissionState(microphonePermission.state);
    };

    checkPermissions();
  }, []);

  const requestPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      stream.getTracks().forEach((track) => track.stop());
console.log("stream", stream);

      setCameraPermissionState("granted");
      setMicrophonePermissionState("granted");
    } catch (error) {
      console.error("Error accessing camera and microphone:", error);
    }
  };

  return {
    requestPermissions,
    cameraPermissionState,
    microphonePermissionState,
  };
};

export default useCheckPermissions;
