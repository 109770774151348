import React, { ReactNode, Suspense, useCallback, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimationProps, AnimatePresence } from "framer-motion";

import OpenCloseButton from "../openCloseButton";
import { getInjectedStyle } from "../../utils/injectStyleFromObject";
import { prependMarginAndCapitalize } from "src/utils/prependMarginAndCapitalize";

export default function SharedLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  const isWidgetClosed = useMemo(() => location.pathname === "/", [location]);
  const myButton = document.querySelector('[data-tdl="tdl-trigger"]');

  const handleToggleWidget = useCallback(() => {
    navigate(isWidgetClosed ? "/login" : "/");
  }, [navigate, isWidgetClosed]);

  const isCallPage = () => location.pathname === "/call";

  const pageStyles = isCallPage() ? prependMarginAndCapitalize(getInjectedStyle()) : {};

  return (
    <div style={{ ...pageStyles, overflow: "hidden" }}>
      <AnimatePresence>
        <AnimatePageWrapper>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </AnimatePageWrapper>
      </AnimatePresence>
      {myButton ? null : location.pathname !== "/call" && !myButton ? (
        <OpenCloseButton widgetIsOpen={!isWidgetClosed} click={handleToggleWidget} />
      ) : null}
    </div>
  );
}

interface AnimationPageProps extends AnimationProps {
  children: ReactNode | JSX.Element;
}

const pageVariants = {
  initial: { opacity: 0, translateY: "100%" },
  in: { opacity: 1, translateY: "0" },
  out: { opacity: 0, translateY: "100%" },
};

const pageTransition = { type: "tween", ease: "linear", duration: 0.4 };

const styles = { height: "100%" };

const AnimatePageWrapper: React.FC<AnimationPageProps> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <motion.div
      key={pathname}
      initial="initial"
      animate="in"
      variants={pageVariants}
      transition={pageTransition}
      exit="out"
      style={styles}
    >
      {children}
    </motion.div>
  );
};
