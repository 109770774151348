// import { createElement } from "react";
// // import SymbolDefs from "/SymbolDefs.svg";

// // import collapse from "../../icons/collapse.svg";
// // import cross from "../../icons/cross.svg";
// // import decline from "../../icons/decline.svg";
// // import expand from "../../icons/expand.svg";
// // import logo from "../../icons/logo.svg";

// export enum IconNames {
//   Logo = "logo",
//   Cross = "cross",
//   Expand = "expand",
//   Collapse = "collapse",
//   Decline = "decline",
//   // Loader = "loader",
// }

// interface Props {
//   name: IconNames;
//   color?: string;
//   width?: string;
//   height?: string;
//   size?: string;
// }
// //@ts-ignore
// export default function Icon({ name, width = "24px", height = "24px", size, color }: Props) {
//   // const Icons: Record<IconNames, any> = {
//   //   [IconNames.Logo]: logo,
//   //   [IconNames.Cross]: cross,
//   //   [IconNames.Expand]: expand,
//   //   [IconNames.Collapse]: collapse,
//   //   [IconNames.Decline]: decline,
//   // };

//   // return (
//   //   <img height={size ? size : height} width={size ? size : width} src={Icons[name]} alt={name} />
//   // );

//   return (
//     <svg height={size ? size : height} width={size ? size : width} color={color}>
//       {createElement("use", {
//         // href: `${process.env.REACT_APP_API_HOST}/widget-front/build/SymbolDefs.svg#${name}`,
//         // xlinkHref: `${process.env.REACT_APP_API_HOST}/widget-front/build/SymbolDefs.svg#${name}`,
//       })}
//     </svg>
//   );
// }

import React, { ReactComponentElement } from "react";
import { ReactComponent as Logo } from "../../icons/logo.svg";
import { ReactComponent as Cross } from "../../icons/cross.svg";
import { ReactComponent as Expand } from "../../icons/expand.svg";
import { ReactComponent as Collapse } from "../../icons/collapse.svg";
import { ReactComponent as Decline } from "../../icons/decline.svg";

export enum IconNames {
  Logo = "logo",
  Cross = "cross",
  Expand = "expand",
  Collapse = "collapse",
  Decline = "decline",
}

interface Props {
  name: IconNames;
  color?: string;
  width?: string;
  height?: string;
  size?: string;
}

const getIconComponent = (name: IconNames): ReactComponentElement<any> |null=> {
  switch (name) {
    case IconNames.Logo:
      return <Logo />;
    case IconNames.Cross:
      return <Cross />;
    case IconNames.Expand:
      return <Expand />;
    case IconNames.Collapse:
      return <Collapse />;
    case IconNames.Decline:
      return <Decline />;
    default:

      return null;
  }
};

const Icon: React.FC<Props> = ({ name, width = "24px", height = "24px", size, color }: Props) => {
  const iconComponent = getIconComponent(name);

  return (
    <div style={{ display: "inline-flex" }}>
      {iconComponent &&
        React.cloneElement(iconComponent, {
          width: size ? size : width,
          height: size ? size : height,
          color,
        })}
    </div>
  );
};


export default Icon;
