import React from "react";

import Icon, { IconNames } from "./Icon/Icon";
import ToggleButton from "./ToggleButton/ToggleButton";
import { getInjectedStyle } from "src/utils/injectStyleFromObject";

type Props = {
  click: React.MouseEventHandler | any;
  widgetIsOpen?: boolean;
  accentColor?: string;
  hover?: any;
};

export default function OpenCloseButton({  click }: Props) {
  const style = getInjectedStyle();

  type StyleObject = Record<string, string>;

  const checkAndAdjustMargins = (styleObject: StyleObject): StyleObject => {
    const hasRight = styleObject.hasOwnProperty("right");

    if (hasRight) {
      return { marginLeft: "auto" };
    } else {
      return { marginRight: "auto" };
    }
  };

  return (
    <ToggleButton buttonStyles={{ ...checkAndAdjustMargins(style) }} onClick={click}>
      <Icon name={IconNames.Logo} size="32px"/>
      <Icon name={IconNames.Cross} size="32px"/>
    </ToggleButton>
  );
}
