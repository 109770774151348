import { createContext, useContext, Dispatch } from 'react';

const ClientContext = createContext<{
  client: { id: number; token: string };
  setClient: Dispatch<{ id: number; token: string }>;
}>({
  client: { id: 26, token: '' },
  setClient: () => {},
});

const ClientProvider = ClientContext.Provider;

const useClient = () => {
  return useContext(ClientContext);
};

export { ClientContext, ClientProvider, useClient };
