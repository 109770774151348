import { createContext, Dispatch, useState } from 'react';

type WidgetType = {
  userId: number;
  widgetId: number;
  signLanguage: string;
  token: string;
};

export const widgetInitialState = {
  userId: 0,
  widgetId: 0,
  signLanguage: '',
  token: '',
};

export const WidgetContext = createContext<{
  widget: WidgetType;
  setWidget: Dispatch<WidgetType>;
}>({
  widget: widgetInitialState,
  setWidget: () => {},
});

export const WidgetProvider = ({ children }) => {
  const [widget, setWidget] = useState(widgetInitialState);
  return (
    <WidgetContext.Provider value={{ widget, setWidget }}>
      {children}
    </WidgetContext.Provider>
  );
};
