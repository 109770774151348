import styled from "styled-components";

const Title = styled.h1<{ color?: string }>(({ theme, color }) => ({
  fontFamily: theme.font.main,
  fontSize: theme.px.x6,
  fontWeight: 400,
  color: color ? color : theme.color.black,
  letterSpacing: 0,
  margin: 0,
}));

const Subtitle = styled.h2<{ color?: string }>(({ theme, color }) => ({
  fontFamily: theme.font.main,
  fontSize: theme.px.x5,
  fontWeight: 600,
  color: color ? color : theme.color.white,
  letterSpacing: 0,
  margin: 0,
}));

const Text = styled.p<{ color: string }>(({ theme, color }) => ({
  fontFamily: theme.font.main,
  // fontSize: theme.px.x4,
  fontSize: theme.px.x4,
  fontWeight: 400,
  color: color,
  letterSpacing: 0,
  margin: 0,
}));

const ButtonText = styled.p<{ color?: string }>(({ theme, color }) => ({
  fontFamily: theme.font.main,
  fontWeight: 600,
  fontSize: theme.px.x4,
  color: color ? color : "inherit",
  margin: 0,
}));

export { ButtonText, Subtitle, Text, Title };
