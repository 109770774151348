import { pxs } from "./pxs";
import { font } from "./fonts";
import { color } from "./colors";
import { Theme } from "./types";
import { zIndexs } from "./zIndexs";

// need to export for tests
export const defaultTheme: Theme = {
  px: pxs,
  font: font,
  color: color,
  zIndex: zIndexs,
};
