import { useCallback, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io-client/dist/socket.io";
import { BalanceType } from "../types/commonTypes";

type Props = {
  setBalance: (c: BalanceType) => void;
};

type ReturnType = {
  setSocket: ({
    token,
    signLanguage,
  }: {
    token: string | null;
    signLanguage: string;
  }) => (() => void) | undefined;
  callSocket: React.MutableRefObject<Socket<DefaultEventsMap, DefaultEventsMap> | null>;
  disconnectSocket: () => void;
  rootSocket: React.MutableRefObject<Socket<DefaultEventsMap, DefaultEventsMap> | null>;
  isLoading: boolean;
};

export const useSocket = ({ setBalance }: Props): ReturnType => {
  const callSocket = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null);
  const rootSocket = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null);
  const tokenRef = useRef(null);
  const timerNoConnection = useRef<null | NodeJS.Timer>(null);
  const [isLoading, setIsLoading] = useState(true);

  // const checkSocketConnection = () => {
  //   // Console.success('Toast.status', WaitingConnection.status)
  //   // Console.info('Token: ', tokenRef.current?.length)
  //   // Console.info('connected: ', callSocket.current?.connected, rootSocket.current?.connected)

  //   if (
  //     (!callSocket.current?.connected || !rootSocket.current?.connected) &&
  //     tokenRef.current &&
  //     WaitingConnection.status === 'hidden'
  //   ) {
  //     WaitingConnection.show({
  //       type: 'waitingConnection',
  //       message: translate('serverConnection'),
  //       toastId: 'waitingConnection',
  //       animateFrom: 'top',
  //       autoHide: false,
  //     });

  //     return false;
  //   }

  //   if (
  //     callSocket.current?.connected &&
  //     rootSocket.current?.connected &&
  //     WaitingConnection.status === 'shown'
  //   ) {
  //     WaitingConnection.hide();
  //   }

  //   if (!tokenRef.current) {
  //     WaitingConnection.hide();
  //   }
  // };

  // function handleCheckSocketConnection(timerId?: NodeJS.Timer, time = 2000) {
  //   if (timerId) {
  //     clearInterval(timerId);
  //   }

  //   timerId = setTimeout(() => {
  //     checkSocketConnection();
  //   }, time);

  //   return timerId;
  // }

  const setSocket = useCallback(
    ({ token, signLanguage }) => {
      if (!token)
        return () => {
          disconnectSocket();
        };

      tokenRef.current = token;
      setIsLoading(true);
      // timerNoConnection.current = handleCheckSocketConnection(undefined, 3000);

      const options = {
        auth: { token },
        extraHeaders: {
          "x-interpreter-app-type": "widget",
          "x-interpreter-locale": signLanguage,
        },
      };

      const optionsCall = {
        path: "/v2",
        extraHeaders: {
          authorization: `Bearer ${token}`,
          "x-interpreter-app-type": "widget",
        },
      };

      rootSocket.current = io(process.env.REACT_APP_SOCKET_URL!, options);
      callSocket.current = io(`${process.env.REACT_APP_SOCKET_URL}/call`, optionsCall);

      callSocket.current.on("connect", () => {
        console.log("callSocket connected: ", callSocket.current?.id);
        // handleCheckSocketConnection();
        setIsLoading(false);
      });

      callSocket.current.on("disconnect", () => {
        console.log("callSocket disconnected: ", callSocket.current?.id);
        // checkSocketConnection();
        setIsLoading(false);
      });

      callSocket.current.on("exception", (payload) => {
        console.log("ERROR ALL", payload);

        // if (payload?.code === 401) {
        //   logout()
        // }
      });

      callSocket.current.on("error", (error) => {
        console.log("callSocket error: ", error);
      });

      rootSocket.current.on("connect", () => {
        console.log("rootSocket connected: ", rootSocket.current?.id);
        // handleCheckSocketConnection();
      });

      rootSocket.current.on("reconnect_error", (error) => {
        console.log("reconnect_error: ", error);
      });

      rootSocket.current.on("ws:client:bookings", (payload) => {
        console.log("bookings", payload);
      });

      rootSocket.current.on("disconnect", () => {
        console.log("rootSocket disconnected: ", rootSocket.current?.id);
        // checkSocketConnection();
      });

      rootSocket.current.on("error", (error) => {
        console.log("rootSocket error: ", error);
      });

      rootSocket.current.on("ws:error", (error) => {
        console.log("rootSocket ws error: ", error);
      });

      rootSocket.current.on("ping", (data) => {
        console.log("rootSocket ping: ", data);
      });

      rootSocket.current.on("ws:balance:update", (data) => {
        console.log("update balance by socket: ", data);
        setBalance(data);
        // const isJSON = isJson(data);
        // if (isJSON) {
        //   setBalanceMiddleware(setBalance)(JSON.parse(data));
        // } else {
        //   setBalanceMiddleware(setBalance)(data);
        // }
      });

      return () => {
        disconnectSocket();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function disconnectSocket() {
    console.log("disconnectSocket");
    rootSocket.current?.disconnect();
    callSocket.current?.disconnect();
    if (timerNoConnection.current) {
      clearTimeout(timerNoConnection.current as unknown as number);
    }
    // checkSocketConnection();
  }

  return {
    setSocket,
    callSocket,
    disconnectSocket,
    rootSocket,
    isLoading,
  };
};
