import { Route, Routes, useNavigate } from "react-router-dom";
import { lazy, useCallback, useEffect, useRef } from "react";
import { io, Socket } from "socket.io-client";
import axios from "axios";

import { useBrowser, useCheckPermissions, useSocket, useWidget, useWidgetSocket } from "./hooks";
import SharedLayout from "./components/SharedLayout/SharedLayout";
import Permissions from "./pages/Permissions/Permissions";

const Call = lazy(() => import("./pages/Call/Call"));
const Captcha = lazy(() => import("./pages/Captcha/Captcha"));

function App() {
  const { setWidgetSocket } = useWidgetSocket();
  const widgetRef = document.getElementById("tdl_widget")!;
  const apiKey = widgetRef.getAttribute("api-key");

  const socketUrl = "https://142.93.172.116/";
  const socketRef = useRef<Socket | null>(null);
  const { browser } = useBrowser();
  const { setWidget } = useWidget();
  const { setSocket } = useSocket({ setBalance: () => {} });
  const navigate = useNavigate();
  const { cameraPermissionState, microphonePermissionState } = useCheckPermissions();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleClick = useCallback(() => {
    if (cameraPermissionState === "granted" && microphonePermissionState === "granted") {
      navigate("/call");
    } else {
      navigate("/permissions");
    }
  }, [navigate, cameraPermissionState, microphonePermissionState]);

  useEffect(() => {
    const myButton = document.querySelector('[data-tdl="tdl-trigger"]');

    if (myButton) {
      myButton.addEventListener("click", handleClick);
    }
    return () => {
      if (myButton) {
        myButton.removeEventListener("click", handleClick);
      }
    };
  }, [handleClick]);

  useEffect(() => {
    if (!apiKey || !window.location.hostname) return;

    const { name, identifier } = browser;
    try {
      axios
        .post(
          `${apiUrl}/auth/widget`,
          {
            browser: name,
            identifier,
            type: "widget",
          },
          {
            headers: {
              "x-api-key": apiKey,
              "x-api-domain": window.location.hostname,
            },
          }
        )
        .then((res) => {
          const { user_id, id, language, token } = res.data;
          setWidget({
            userId: user_id,
            widgetId: id,
            signLanguage: language,
            token: token,
          });
          setSocket({ token: token, signLanguage: language });
        });
    } catch (e) {
      console.log("Error ====>>", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiKey) return;

    const onConnect = () => {
      console.log("socket connected: ", socketRef.current!.id);

      setWidgetSocket({ apiKey, hostname: window.location.hostname });
    };

    const onDisconnect = () => {
      console.log("socket disconnected: ", socketRef.current!.id);
    };

    const onException = (payload) => {
      console.log("socket exception: ", payload);
    };

    socketRef.current = io(`${socketUrl}/widget`, {
      path: "/v2",
      extraHeaders: {
        "x-api-key": apiKey,
        "x-api-domain": window.location?.hostname || window.location?.href,
      },
    });

    if (socketRef.current === null) return;
    socketRef.current.on("connect", onConnect);
    socketRef.current.on("disconnect", onDisconnect);
    socketRef.current.on("exception", onException);

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        <Route path="login" element={<Captcha />} />
        <Route path="call" element={<Call />} />
        <Route path="permissions" element={<Permissions />} />
      </Route>
    </Routes>
  );
}

export default App;
