import { zIndexMap } from "./types";

export const zIndexs: zIndexMap = {
  z0: 0,
  z1: 100,
  z2: 200,
  z3: 300,
  z4: 400,
  z5: 500,
  z6: 600,
  z10: 1000,
};
