import { createRoot } from "react-dom/client";
import { MemoryRouter } from "react-router-dom";
import ZoomVideo from "@zoom/videosdk";

import App from "./App";
import { WidgetConfigProvider, WidgetProvider } from "./context";
import ZoomContext from "./context/ZoomContext";
import ManageThemeProvider from "./theme/ThemeContext";
import { getAttributesAsObject } from "./utils/getAttributesAsObject";

import "./i18n";
import "./index.scss";

const zmClient = ZoomVideo.createClient();
export const rootElement = document.getElementById("tdl_widget");
const root = createRoot(rootElement!);
const attributes:Record<string,string> = getAttributesAsObject(rootElement);

root.render(
  <ManageThemeProvider accentColor={attributes.accentcolor ? attributes.accentcolor : "#766AEE"}>
    <WidgetProvider>
      <WidgetConfigProvider>
        <MemoryRouter basename="" >
          <ZoomContext.Provider value={zmClient}>
            <App />
          </ZoomContext.Provider>
        </MemoryRouter>
      </WidgetConfigProvider>
    </WidgetProvider>
  </ManageThemeProvider>
);
