import React, { createContext, useContext } from "react";
import { ThemeProvider } from "styled-components";

import { defaultTheme } from "./defaultTheme";

const ThemeContext = createContext(defaultTheme);
export const useTheme = () => useContext(ThemeContext);

const ManageThemeProvider = ({
  children,
  accentColor,
}: {
  children: React.ReactNode | React.ReactNode[] | null;
  accentColor: string;
}) => {
  const theme = { ...defaultTheme, color: { ...defaultTheme.color, accentColor } };

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ManageThemeProvider;
