import React, { ReactElement, ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import { useTheme } from "../../theme/ThemeContext";

interface Props {
  children: ReactNode;
  onClick: () => void;
  buttonStyles?: React.CSSProperties;
}

export default function ToggleButton({
  children,
  onClick,
  buttonStyles,
}: Props): ReactElement {
  const isKeyboardActive = useDetectKeyboardOpen();

  const icons = React.Children.toArray(children);

  const [current, setCurrent] = React.useState(0);
  const theme = useTheme();
  function handleClick() {
    setCurrent(current === icons.length - 1 ? 0 : current + 1);
    onClick();
  }

  const isInitial = React.useRef(true);

  React.useEffect(() => {
    isInitial.current = false;
  }, []);

  const colorMap = [theme.color.accentColor, "#000"];

  return (
    <IconButton
      $isKeyboardActive={isKeyboardActive ? isKeyboardActive : false}
      style={buttonStyles}
      $backgroundColor={colorMap[current]}
      onClick={handleClick}
    >
      {icons.map((icon, i) => {
        const isCurrent = i === current;
        return (
          <Icon key={i} $isInitial={isInitial.current} $isCurrent={isCurrent}>
            {icon}
          </Icon>
        );
      })}
    </IconButton>
  );
}

const IconButton = styled.button<{ $backgroundColor: string; $isKeyboardActive: boolean }>(
  ({ $backgroundColor, $isKeyboardActive }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    border: "1px solid #fff",
    height: $isKeyboardActive ? "32px" : "64px",
    width: $isKeyboardActive ? "32px" : "64px",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    outline: "none",
    borderRadius: "12px",
    color: "#fff",
    backgroundColor: $backgroundColor,
    transition: "background-color 600ms linear, height 250ms linear, width 250ms linear",
  })
);

const Icon = styled.div<{ $isInitial: boolean; $isCurrent: boolean }>`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-fill-mode: forwards;
  animation-duration: ${(props) => (props.$isInitial ? 0 : 600)}ms;
  animation-name: ${(props) => (props.$isCurrent ? riseIn : riseOut)};
`;

const riseIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const riseOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;
